export const BlockCoverAnimationFields = ['*', {
  items: [{
    block_cover_animation_items_id: [{
      translations: ['*'],
    }],
  }],
}]

export const BlockCoverNavigationFields = ['*']

export const BlockCarouselManualFields = ['*', {
  items: [{
    block_carousel_manual_item_id: ['*', {
      translations: ['*'],
    }],
  }],
}]

export const BlockSingleQuoteFields = ['*', {
  translations: ['*'],
}]

export const BlockImageTextFields = ['*', {
  translations: ['*'],
  images: [{
    directus_files_id: [
      'id',
      'title',
      'description',
    ],
  }],
}]

export const BlockFooterCTAFields = ['*', {
  translations: ['*'],
}]

export const BlockCoverUPSFields = ['*', {
  translations: ['*'],
}]

export const BlockCoverVariantsFields = ['*', {
  translations: ['*'],
}]

export const BlockCoverPaletteFields = ['*', {
  translations: ['*'],
  items: ['*', {
    block_cover_palette_item_id: ['*', {
      translations: ['*'],
    }],
  }],
}]

export const BlockCarouselImageSelectFields = ['*', {
  translations: ['*'],
  items: ['*', {
    block_carousel_image_select_items_id: ['*', {
      translations: ['*'],
    }],
  }],
}]

export const BlockProjectStatsFields = ['*', {
  items: [{
    block_project_stats_item_id: ['*', {
      translations: ['*'],
      video: ['*'],
    }],
  }],
}]

export const BlockImageCollectionFields = ['*', {
  images: ['*', {
    directus_files_id: ['*'],
  }],
}]

export const BlockFAQCollectionFields = ['*', {
  translations: ['*'],
  categories: ['*', {
    block_faq_collection_category_id: ['*', {
      translations: ['*'],
      items: ['*', {
        block_faq_entry_id: ['*', {
          translations: ['*'],
        }],
      }],
    }],
  }],
}]

export const BlockFormFields = ['*', {
  translations: ['*'],
  images: [{
    directus_files_id: [
      'id',
      'title',
      'description',
    ],
  }],
}]

export const BlockImageWithTextCollectionFields = ['*', {
  block_image_with_text_single_id: ['*', {
    block_image_with_text_single_id: ['*', {
      id: 'id',
      translations: ['*'],
      image: ['*', {
        directus_files_id: [
          'id',
          'title',
          'description',
        ],
      }],
    }],
  }],
}]

export const BlockSimpleSpecificationFields = ['*', {
  translations: ['*'],
  buttons: ['*', {
    block_simple_specification_button_id: ['*', {
      id: 'id',
      translations: ['*'],
      image: ['*', {
        directus_files_id: [
          'id',
          'title',
          'description',
        ],
      }],
    }],
  }],
}]

export const BlockVerticalSliderFields = ['*', {
  items: [{
    block_vertical_slider_item_id: ['*', {
      translations: ['*'],
    }],
  }],
}]

export const BlockQuoteCarouselFields = ['*', {
  translations: ['*'],
  items: ['*', {
    block_quote_carousel_item_id: ['*', {
      translations: ['*'],
    }],
  }],
}]
