<script setup lang="ts">
const linkEnergy = '/energy-savings'
const linkControl = '/control'
const linkConstruction = '/construction'
const linkSafety = '/safety'
</script>

<template>
  <div class="relative">
    <BlockBase is="div" is-light class="absolute inset-x-0 bottom-0 top-1/2 hidden bg-neutral-white md:block" />
    <Container class="relative hidden items-center justify-center py-5rem md:flex">
      <div class="relative z-10 h-400px w-full md:h-unset">
        <img class="pointer-events-none absolute left-1/2 z-10 h-400px min-w-590px md:relative md:h-auto md:w-full -translate-x-1/2" src="/images/home/home-swimming-pool.png">
        <!-- Buttons -->
        <div class="absolute top-50px hidden -left-100px 2xl:left-200px 2xl:top-180px lg:left-0px lg:top-100px xl:left-100px xl:top-150px md:flex">
          <div>
            <BtnArrow class="relative left-100px z-10 max-h-max" :link="linkEnergy" :text="$t('block.pool_navigation.energy_saving')" :arrow="true" :light="false" />
          </div>
          <SpecialStairsSvg class="relative top-20px" />
        </div>
        <div class="absolute top-0px hidden -right-100px 2xl:right-200px 2xl:top-100px lg:right-0px lg:top-50px xl:right-70px xl:top-80px md:flex">
          <SpecialAfdekkingSvg class="relative top-20px" />
          <div>
            <BtnArrow class="relative right-50px z-10 lg:right-0" :link="linkControl" :text="$t('block.pool_navigation.control')" :arrow="true" :light="false" />
          </div>
        </div>
        <div class="absolute bottom-0 left-20px hidden items-end 2xl:left-300px lg:left-120px xl:left-200px md:flex">
          <BtnArrow class="relative z-10" :link="linkConstruction" :text="$t('block.pool_navigation.construction')" :arrow="true" :light="true" />
          <SpecialAccessoiresSvg class="relative bottom-20px" />
        </div>
        <div class="absolute bottom-80px hidden items-end -right-100px 2xl:bottom-230px 2xl:right-200px lg:bottom-120px xl:bottom-180px xl:right-80px md:flex lg:-right-10px">
          <SpecialPoolsSvg class="relative bottom-20px left-50px" />
          <BtnArrow class="relative right-20px z-10 lg:right-0" :link="linkSafety" :text="$t('block.pool_navigation.safety')" :arrow="true" :light="true" />
        </div>
        <!-- Buttons -->
        <!-- Background Text -->
        <p class="prestige-text absolute left-1/2 top-78% z-0 min-w-590px w-full text-center text-7rem text-starline-primary/10 font-title lg:top-74% md:top-72% -translate-x-1/2 lg:text-15rem md:text-12rem sm:text-10rem xl:text-20rem">
          Pure prestige
        </p>
      </div>
      <!-- Top Background Block -->
      <div class="absolute left-1/2 z-0 h-50% w-110vw bg-starline-secondary -top-1px -translate-x-1/2" />
      <!-- Mobile Menu -->
      <div class="absolute left-1/2 top-1/2 z-10 w-full flex flex-wrap items-center justify-center gap-0.5rem px-2.5rem py-1rem md:hidden -translate-x-1/2 -translate-y-1/2">
        <BtnArrow :link="linkEnergy" :text="$t('block.pool_navigation.energy_saving')" :arrow="true" :light="true" />
        <BtnArrow :link="linkControl" :text="$t('block.pool_navigation.control')" :arrow="true" :light="true" />
        <BtnArrow :link="linkConstruction" :text="$t('block.pool_navigation.construction')" :arrow="true" :light="true" />
        <BtnArrow :link="linkSafety" :text="$t('block.pool_navigation.safety')" :arrow="true" :light="true" />
      </div>
    </Container>
    <Container class="relative flex flex-col items-center justify-center py-20 md:hidden">
      <div class="relative w-full flex flex-col items-center">
        <p class="absolute z-0 w-full break-words text-center text-8rem text-starline-primary/10 line-height-none font-title -top-40">
          Pure <br>prestige
        </p>

        <img class="z-10 w-full object-contain" src="/images/home/home-swimming-pool.png">
        <div class="h-48 w-px bg-white -z-10 -mb-40 -translate-y-40" />
        <div class="w-full flex flex-col gap-4">
          <BtnArrow class="w-full flex items-center justify-center text-center" :link="linkEnergy" :text="$t('block.pool_navigation.energy_saving')" :arrow="true" :light="false" />
          <BtnArrow class="w-full flex items-center justify-center text-center" :link="linkControl" :text="$t('block.pool_navigation.control')" :arrow="true" :light="false" />
          <BtnArrow class="w-full flex items-center justify-center text-center" :link="linkConstruction" :text="$t('block.pool_navigation.construction')" :arrow="true" :light="false" />
          <BtnArrow class="w-full flex items-center justify-center text-center" :link="linkSafety" :text="$t('block.pool_navigation.safety')" :arrow="true" :light="false" />
        </div>
      </div>
    </Container>
  </div>
</template>

<style scoped>
.prestige-text {
  white-space: nowrap;
}
</style>
